import React from "react";

const Logo = () =>  (
  <svg className="header__logo" width="481" height="255" viewBox="0 0 481 255" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.4801 159.08H54.4801L48.1601 174.54H39.5601L66.0001 110H73.5501L100.7 174.55H91.8901L85.4801 159.08ZM57.4801 151.67H82.4801L69.8501 121.14L57.4801 151.67Z" fill="#093D5E"/>
    <path d="M103.07 149.64C103.07 134.8 114.21 123.64 128.94 123.64C140.82 123.64 150.13 130.72 153.15 141.51H145.39C143.955 138.374 141.632 135.727 138.708 133.898C135.784 132.069 132.388 131.138 128.94 131.22C118.72 131.22 111.08 139.22 111.08 149.62C111.08 160.02 118.81 168.02 128.94 168.02C132.41 168.088 135.821 167.121 138.738 165.242C141.656 163.363 143.947 160.657 145.32 157.47H153.25C150.25 168.47 140.71 175.61 128.94 175.61C114.22 175.63 103.07 164.47 103.07 149.64Z" fill="#093D5E"/>
    <path d="M188.4 167.29V174.54H182C171.31 174.54 164.71 167.93 164.71 157.13V131.83H155.94L172.54 114.21V124.75H188.16V131.83H172.49V156.83C172.49 163.53 176.19 167.24 182.96 167.24L188.4 167.29Z" fill="#093D5E"/>
    <path d="M195.68 107.14H204.17V116.14H195.68V107.14ZM195.68 124.75H204.17V174.54H195.68V124.75Z" fill="#093D5E"/>
    <path d="M261.11 124.75L240.11 174.54H233L211.46 124.75H219.65L236.55 164.36L253.11 124.75H261.11Z" fill="#093D5E"/>
    <path d="M316.05 124.75V174.54H309.56L308.73 166.99C306.305 169.771 303.299 171.985 299.924 173.478C296.55 174.971 292.889 175.706 289.2 175.63C274.47 175.63 263.42 164.47 263.42 149.63C263.42 134.79 274.47 123.63 289.2 123.63C292.912 123.549 296.596 124.288 299.99 125.796C303.383 127.303 306.402 129.541 308.83 132.35L309.83 124.72L316.05 124.75ZM308.05 149.64C308.05 139.05 300.34 131.24 289.79 131.24C284.91 131.24 280.23 133.179 276.779 136.629C273.329 140.08 271.39 144.76 271.39 149.64C271.39 154.52 273.329 159.2 276.779 162.651C280.23 166.101 284.91 168.04 289.79 168.04C300.34 168 308.05 160.23 308.05 149.64Z" fill="#093D5E"/>
    <path d="M356 167.29V174.54H349.55C338.85 174.54 332.25 167.93 332.25 157.13V131.83H323.49L340.09 114.21V124.75H355.7V131.83H340.13V156.83C340.13 163.53 343.82 167.24 350.6 167.24L356 167.29Z" fill="#093D5E"/>
    <path d="M411.57 152.68H369.12C370.35 161.9 377.21 167.96 386.65 167.96C393.53 167.96 399.08 164.74 402.15 159.36H410.41C406.2 169.63 397.41 175.63 386.65 175.63C372.2 175.63 361.25 164.47 361.25 149.63C361.25 134.79 372.2 123.63 386.65 123.63C401.75 123.63 411.74 135.27 411.74 149.82C411.74 150.79 411.65 151.74 411.57 152.68ZM386.64 131.07C377.64 131.07 370.92 136.76 369.3 145.43H403.88C402.2 136.57 395.46 131.07 386.65 131.07H386.64Z" fill="#093D5E"/>
    <path d="M415.31 48.7L388.76 57.21L362.22 65.71L371.22 74.71L380.22 83.71L371.71 110.25L398.25 101.74L407.25 110.74L416.25 119.74L424.75 93.19L433.26 66.65L441.77 40.11L415.31 48.7ZM415.82 84.26L406.82 75.26L397.82 66.26L424.36 57.75L415.82 84.26Z" fill="url(#paint0_linear)"/>
    <path d="M380.26 83.75L389.27 92.77L371.75 110.29L380.26 83.75Z" fill="#CBC24F"/>
    <path d="M389.27 92.77L380.26 83.75L397.78 66.22L389.27 92.77Z" fill="#C2C15A"/>
    <path d="M397.78 66.22L406.8 75.24L389.27 92.77L397.78 66.22Z" fill="#A4BC76"/>
    <path d="M397.78 66.22L388.76 57.2L371.24 74.73L397.78 66.22Z" fill="#E6C729"/>
    <path d="M371.24 74.73L380.26 83.75L397.78 66.22L371.24 74.73Z" fill="#DDC538"/>
    <path d="M362.22 65.71L371.24 74.73L388.76 57.2L362.22 65.71Z" fill="#EFC919"/>
    <path d="M416.33 119.82L407.31 110.8L424.83 93.28L416.33 119.82Z" fill="#6DB499"/>
    <path d="M398.29 101.78L389.27 92.77L371.75 110.29L398.29 101.78Z" fill="#D5C444"/>
    <path d="M415.82 84.26L406.8 75.24L389.27 92.77L415.82 84.26Z" fill="#AFBD6D"/>
    <path d="M433.34 66.73L424.32 57.72L441.85 40.19L433.34 66.73Z" fill="#10AFB4"/>
    <path d="M415.31 48.7L424.32 57.72L441.85 40.19L415.31 48.7Z" fill="#5FB3A0"/>
    <path d="M389.27 92.77L398.29 101.78L415.82 84.26L389.27 92.77Z" fill="#9ABA7E"/>
    <path d="M424.32 57.72L433.34 66.73L415.82 84.26L424.32 57.72Z" fill="#4EB1A7"/>
    <path d="M424.83 93.28L415.82 84.26L433.34 66.73L424.83 93.28Z" fill="#39B0AE"/>
    <path d="M388.76 57.2L397.78 66.22L415.31 48.7L388.76 57.2Z" fill="#B8BF64"/>
    <path d="M424.32 57.72L415.31 48.7L397.78 66.22L424.32 57.72Z" fill="#90B885"/>
    <path d="M415.82 84.26L424.83 93.28L407.31 110.8L415.82 84.26Z" fill="#79B593"/>
    <path d="M407.31 110.8L398.29 101.78L415.82 84.26L407.31 110.8Z" fill="#85B78C"/>
    <path d="M141.73 205.14C141.675 203.898 141.878 202.659 142.327 201.499C142.775 200.34 143.458 199.286 144.334 198.404C145.209 197.522 146.258 196.83 147.414 196.374C148.57 195.917 149.808 195.705 151.05 195.75C153.006 195.683 154.927 196.284 156.496 197.454C158.065 198.625 159.188 200.295 159.68 202.19H157.35C156.885 200.909 156.023 199.81 154.89 199.052C153.757 198.295 152.412 197.919 151.05 197.98C149.199 198.049 147.446 198.833 146.161 200.168C144.876 201.502 144.158 203.282 144.158 205.135C144.158 206.988 144.876 208.768 146.161 210.102C147.446 211.437 149.199 212.221 151.05 212.29C152.457 212.345 153.843 211.937 154.997 211.13C156.151 210.323 157.009 209.16 157.44 207.82H159.76C159.308 209.772 158.192 211.507 156.604 212.728C155.016 213.95 153.053 214.583 151.05 214.52C149.809 214.565 148.572 214.353 147.416 213.897C146.261 213.441 145.213 212.751 144.337 211.869C143.462 210.988 142.778 209.936 142.33 208.778C141.881 207.619 141.677 206.381 141.73 205.14Z" fill="#093D5E"/>
    <path d="M164.92 205.14C164.894 203.249 165.431 201.394 166.463 199.809C167.495 198.225 168.975 196.983 170.714 196.242C172.454 195.501 174.375 195.295 176.232 195.648C178.089 196.002 179.8 196.9 181.145 198.229C182.49 199.557 183.41 201.256 183.788 203.109C184.165 204.961 183.982 206.884 183.263 208.633C182.545 210.382 181.322 211.877 179.751 212.929C178.179 213.981 176.331 214.542 174.44 214.54C173.188 214.584 171.941 214.372 170.774 213.916C169.607 213.461 168.545 212.772 167.654 211.892C166.762 211.012 166.06 209.959 165.59 208.798C165.12 207.637 164.892 206.392 164.92 205.14ZM181.64 205.14C181.646 203.715 181.229 202.32 180.441 201.132C179.654 199.944 178.531 199.016 177.216 198.467C175.901 197.917 174.452 197.77 173.054 198.045C171.655 198.319 170.369 199.002 169.36 200.008C168.35 201.014 167.661 202.297 167.381 203.694C167.1 205.092 167.241 206.541 167.785 207.858C168.329 209.176 169.252 210.302 170.437 211.094C171.621 211.887 173.015 212.31 174.44 212.31C175.39 212.337 176.337 212.171 177.22 211.821C178.104 211.471 178.908 210.944 179.582 210.273C180.255 209.602 180.785 208.801 181.139 207.918C181.493 207.036 181.664 206.091 181.64 205.14Z" fill="#093D5E"/>
    <path d="M203.45 196V214.2H201.53L191.17 200V214.21H188.94V196H191L201.22 210V196H203.45Z" fill="#093D5E"/>
    <path d="M210.86 198.2V204.2H218.13V206.31H210.86V214.23H208.58V196H218.81V198.16L210.86 198.2Z" fill="#093D5E"/>
    <path d="M234.62 212.05V214.24H224V196H234.3V198.16H226.3V203.78H233.65V205.9H226.3V212.01L234.62 212.05Z" fill="#093D5E"/>
    <path d="M246.15 207.11H242V214.24H239.72V196H246.63C250.03 196 252.55 198.31 252.55 201.56C252.565 202.756 252.179 203.922 251.454 204.874C250.729 205.825 249.707 206.507 248.55 206.81L252.91 214.2H250.33L246.15 207.11ZM242 205H246.51C246.98 205.044 247.454 204.99 247.902 204.841C248.349 204.691 248.761 204.45 249.11 204.132C249.459 203.814 249.738 203.427 249.929 202.996C250.12 202.564 250.219 202.097 250.219 201.625C250.219 201.153 250.12 200.686 249.929 200.254C249.738 199.823 249.459 199.436 249.11 199.118C248.761 198.8 248.349 198.559 247.902 198.409C247.454 198.26 246.98 198.206 246.51 198.25H242V205Z" fill="#093D5E"/>
    <path d="M268.79 212.05V214.24H258.2V196H268.5V198.16H260.5V203.78H267.85V205.9H260.5V212.01L268.79 212.05Z" fill="#093D5E"/>
    <path d="M288.58 196V214.2H286.66L276.3 200V214.21H274.06V196H276.13L286.35 210V196H288.58Z" fill="#093D5E"/>
    <path d="M293.73 205.14C293.675 203.897 293.879 202.657 294.327 201.497C294.776 200.337 295.46 199.282 296.337 198.4C297.214 197.518 298.264 196.827 299.421 196.371C300.578 195.915 301.817 195.703 303.06 195.75C305.016 195.683 306.937 196.284 308.506 197.454C310.075 198.625 311.198 200.295 311.69 202.19H309.36C308.895 200.909 308.033 199.81 306.9 199.052C305.767 198.295 304.422 197.92 303.06 197.98C301.209 198.049 299.456 198.833 298.171 200.168C296.886 201.502 296.168 203.282 296.168 205.135C296.168 206.988 296.886 208.768 298.171 210.102C299.456 211.437 301.209 212.221 303.06 212.29C304.466 212.347 305.853 211.941 307.005 211.133C308.158 210.326 309.014 209.161 309.44 207.82H311.77C311.318 209.772 310.202 211.507 308.614 212.728C307.026 213.95 305.063 214.583 303.06 214.52C301.818 214.567 300.58 214.356 299.423 213.9C298.267 213.445 297.217 212.755 296.341 211.873C295.464 210.992 294.78 209.939 294.33 208.78C293.881 207.621 293.677 206.382 293.73 205.14Z" fill="#093D5E"/>
    <path d="M327.52 212.05V214.24H316.93V196H327.23V198.16H319.23V203.78H326.58V205.9H319.23V212.01L327.52 212.05Z" fill="#093D5E"/>
    <path d="M352.85 210.75V214.24H340.73V213.42C347.85 205.2 348.73 203.85 348.73 202.06C348.746 201.793 348.705 201.526 348.61 201.276C348.516 201.026 348.369 200.799 348.18 200.61C347.991 200.421 347.764 200.274 347.514 200.18C347.264 200.085 346.997 200.044 346.73 200.06C346.46 200.05 346.191 200.096 345.941 200.196C345.69 200.296 345.463 200.448 345.275 200.641C345.086 200.834 344.94 201.065 344.847 201.318C344.753 201.571 344.713 201.841 344.73 202.11V202.44H341.01V202.06C340.986 201.317 341.118 200.577 341.397 199.889C341.677 199.2 342.098 198.578 342.634 198.062C343.169 197.547 343.807 197.149 344.506 196.895C345.204 196.642 345.948 196.538 346.69 196.59C350.09 196.59 352.48 198.84 352.48 202.02C352.48 204.14 351.57 206.02 347.53 210.75H352.85Z" fill="#093D5E"/>
    <path d="M358.07 205.58C358.07 200.04 360.75 196.58 365.07 196.58C369.39 196.58 372.07 200.01 372.07 205.58C372.07 211.15 369.36 214.58 365.07 214.58C360.78 214.58 358.07 211.09 358.07 205.58ZM368.35 205.58C368.35 202.02 367.17 200.06 365.08 200.06C362.99 200.06 361.82 202 361.82 205.56C361.82 209.12 362.97 211.06 365.08 211.06C367.19 211.06 368.35 209.16 368.35 205.6V205.58Z" fill="#093D5E"/>
    <path d="M389.31 210.75V214.24H377.18V213.42C384.3 205.2 385.24 203.85 385.24 202.06C385.256 201.793 385.215 201.526 385.12 201.276C385.026 201.026 384.879 200.799 384.69 200.61C384.501 200.421 384.274 200.274 384.024 200.18C383.774 200.085 383.507 200.044 383.24 200.06C382.97 200.048 382.7 200.093 382.449 200.193C382.197 200.292 381.97 200.444 381.781 200.637C381.592 200.831 381.446 201.062 381.353 201.316C381.26 201.57 381.221 201.84 381.24 202.11V202.44H377.52V202.06C377.496 201.317 377.628 200.577 377.907 199.889C378.187 199.2 378.608 198.578 379.144 198.062C379.679 197.547 380.317 197.149 381.016 196.895C381.714 196.642 382.458 196.538 383.2 196.59C386.6 196.59 388.99 198.84 388.99 202.02C388.99 204.14 388.08 206.02 383.99 210.75H389.31Z" fill="#093D5E"/>
    <path d="M394.53 205.58C394.53 200.04 397.21 196.58 401.53 196.58C405.85 196.58 408.53 200.01 408.53 205.58C408.53 211.15 405.82 214.58 401.53 214.58C397.24 214.58 394.53 211.09 394.53 205.58ZM404.8 205.58C404.8 202.02 403.63 200.06 401.54 200.06C399.45 200.06 398.28 202 398.28 205.56C398.28 209.12 399.42 211.06 401.54 211.06C403.66 211.06 404.8 209.16 404.8 205.6V205.58Z" fill="#093D5E"/>
    <defs>
      <linearGradient id="paint0_linear" x1="362.22" y1="80" x2="441.85" y2="80" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EFC919"/>
        <stop offset="1" stop-color="#10AFB4"/>
      </linearGradient>
    </defs>
  </svg>
)

export default Logo;
